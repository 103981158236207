import React, { useState, useEffect } from 'react';
import { Button, IconButton, TextField, Box, Typography, MenuItem, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import AppStyles from './styles/RuleAppStyles'; // Import the styles
import Layout from './styles/RuleLayout'; // Import the layout component

const statuses = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Approved', label: 'Approved' },
  { value: 'Paid', label: 'Paid' },
  { value: 'Rejected', label: 'Rejected' },
];

const companyOptions = [
  { value: 'Anderson Underground', label: 'Anderson Underground' },
  { value: 'Selcon', label: 'Selcon' },
  { value: 'Sellenriek Construction', label: 'Sellenriek Construction' },
  { value: 'Sellenriek Energy', label: 'Sellenriek Energy' },
  { value: 'UtiliSource', label: 'UtiliSource' },
];

const EmailRulesManager = () => {
  const [rules, setRules] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [newRule, setNewRule] = useState({
    companyName: '',
    email: '',
    emailSubject: '',
    emailBody: '',
    status: 'Pending',
  });
  const [editingRule, setEditingRule] = useState(null); // State to track which rule is being edited

  // Fetch the existing email rules from the backend
  const fetchRules = async () => {
    try {
      const response = await fetch('https://sponsorshipbe.azurewebsites.net/api/getEmailRules');
      const data = await response.json();
      setRules(data);
    } catch (error) {
      console.error('Error fetching rules:', error);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingRule(null); // Reset editing mode when modal is closed
  };

  // Call the API to add a new rule
  const addRule = async () => {
    try {
      const response = await fetch('https://sponsorshipbe.azurewebsites.net/api/addEmailRule', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newRule),
      });
      if (response.ok) {
        fetchRules();
        setNewRule({ companyName: '', email: '', emailSubject: '', emailBody: '', status: 'Pending' });
        handleCloseModal(); // Close modal after successful submission
      }
    } catch (error) {
      console.error('Error adding rule:', error);
    }
  };

  // Call the API to update an existing rule
  const updateRule = async (updatedRule) => {
    try {
      const response = await fetch(`https://sponsorshipbe.azurewebsites.net/api/updateEmailRule/${updatedRule.rowKey}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedRule),
      });
      if (response.ok) {
        fetchRules();
        handleCloseModal(); // Close modal after updating rule
      }
    } catch (error) {
      console.error('Error updating rule:', error);
    }
  };

  // Call the API to delete a rule
  const deleteRule = async (rowKey) => {
    try {
      const response = await fetch(`https://sponsorshipbe.azurewebsites.net/api/deleteEmailRule/${rowKey}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        fetchRules();
      }
    } catch (error) {
      console.error('Error deleting rule:', error);
    }
  };

  useEffect(() => {
    fetchRules();
  }, []);

  const handleEditClick = (rule) => {
    setEditingRule(rule); // Set the rule to be edited
    setNewRule({ ...rule }); // Populate the modal with the existing rule data
    handleOpenModal();
  };

  const handleEditCellChange = async (params) => {
    const updatedRule = { ...params.row, [params.field]: params.value };
    await updateRule(updatedRule);
  };

  const handleDeleteClick = (rowKey) => {
    deleteRule(rowKey);
  };

  // DataGrid columns definition
  const columns = [
    { field: 'companyName', headerName: 'Company Name', flex: 1, editable: true },
    { field: 'email', headerName: 'Email', flex: 1, editable: true },
    { field: 'emailSubject', headerName: 'Email Subject', flex: 1, editable: true },
    { field: 'emailBody', headerName: 'Email Body', flex: 1, editable: true },
    { field: 'status', headerName: 'Status', flex: 1, editable: true },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <>
          {/* Edit Icon */}
          <IconButton color="primary" onClick={() => handleEditClick(params.row)}>
            <EditIcon />
          </IconButton>
          {/* Delete Icon */}
          <IconButton color="error" onClick={() => handleDeleteClick(params.row.rowKey)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Box sx={AppStyles.container}>
        <Typography variant="h4" sx={AppStyles.title}>
          Email Rules Management
        </Typography>

        {/* Button to Open Modal */}
        <Box sx={{ marginBottom: '16px', textAlign: 'right' }}>
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            Add New Rule
          </Button>
        </Box>

        {/* Modal for Adding/Editing Rule */}
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box sx={AppStyles.modalBox}>
            <Typography variant="h6">{editingRule ? 'Edit Rule' : 'Add New Rule'}</Typography>
            <TextField
              select
              label="Company Name"
              value={newRule.companyName}
              onChange={(e) => setNewRule({ ...newRule, companyName: e.target.value })}
              fullWidth
            >
              {companyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Email Address"
              value={newRule.email}
              onChange={(e) => setNewRule({ ...newRule, email: e.target.value })}
              fullWidth
            />
            <TextField
              label="Email Subject"
              value={newRule.emailSubject}
              onChange={(e) => setNewRule({ ...newRule, emailSubject: e.target.value })}
              fullWidth
            />
            <TextField
              label="Email Body"
              value={newRule.emailBody}
              onChange={(e) => setNewRule({ ...newRule, emailBody: e.target.value })}
              fullWidth
              multiline
              rows={4}
            />
            <TextField
              select
              label="Status"
              value={newRule.status}
              onChange={(e) => setNewRule({ ...newRule, status: e.target.value })}
              fullWidth
            >
              {statuses.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </TextField>
            <Box sx={AppStyles.modalButtons}>
              <Button
                variant="contained"
                color="primary"
                onClick={editingRule ? () => updateRule(newRule) : addRule}
              >
                {editingRule ? 'Update Rule' : 'Save Rule'}
              </Button>
              <Button variant="outlined" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* DataGrid */}
        <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
          <DataGrid
            rows={rules}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.rowKey}
            onCellEditCommit={handleEditCellChange}
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default EmailRulesManager;
const AppStyles = {
    container: {
      padding: '32px',
      backgroundColor: '#f7f7f7',
      borderRadius: '8px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    },
    title: {
      marginBottom: '24px',
      fontWeight: 700,
      fontSize: '1.8rem',
      color: '#333333',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '12px',
      marginTop: '24px',
    },
  };
  
  export default AppStyles;
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LoginPageStyles from './styles/LoginPageStyles'; // Import the LoginPageStyles

const LoginPage = () => {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    console.log('MSAL inProgress status:', inProgress);
    if (inProgress === 'login') {
      console.log('Login is in progress...');
    }
  }, [inProgress]);

  const handleLogin = () => {
    const redirectUri = window.location.origin + '/submissionlist'; // Ensure this matches the correct URL
    console.log('Starting login process with redirect URI:', redirectUri);

    instance.loginRedirect({
      redirectUri: redirectUri, // Explicitly log and use the correct redirect URI
    })
    .then((response) => {
      console.log('Login successful:', response);
    })
    .catch((error) => {
      console.error('Login error:', error);
    });
  };

  return (
    <Box sx={LoginPageStyles.root}>
      <Container maxWidth="sm" sx={LoginPageStyles.container}>
        <Typography variant="h4" sx={LoginPageStyles.title}>
          Welcome to Sponsorship Dashboard
        </Typography>
        <Typography variant="subtitle1" sx={LoginPageStyles.subtitle}>
          Please sign in to continue
        </Typography>
        
        <Button 
          onClick={handleLogin} 
          variant="contained" 
          color="primary" 
          fullWidth 
          sx={LoginPageStyles.loginButton}
        >
          Login with Azure AD
        </Button>
      </Container>
    </Box>
  );
};

export default LoginPage;
const AppStyles = {
  appBar: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    backgroundColor: '#FF6A00', // Dark orange for the top bar
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 24px',
  },
  menuIcon: {
    color: '#FFFFFF',
    fontSize: '2rem',
  },
  title: {
    color: '#FFFFFF',
    fontWeight: 700,
    fontFamily: '"Roboto", "Arial", sans-serif',
    fontSize: '1.8rem',
  },
  drawer: {
    width: 240, // Set width for the drawer
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: {
      width: 240,
      backgroundColor: '#333333',
      color: '#FFFFFF',
      boxSizing: 'border-box',
      borderRight: 'none',
    },
  },
  listItem: {
    padding: '16px 24px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FF6A00',
    },
  },
  listItemText: {
    fontSize: '1rem',
    fontWeight: 600,
    color: '#FFFFFF',
  },
  icon: {
    color: '#FF6A00',
  },
  mainContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    marginLeft: '240px', // Align content after the drawer
    padding: '24px',
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
};

export default AppStyles;
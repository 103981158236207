import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import SubmissionList from './SubmissionList';
import SubmissionDetails from './SubmissionDetails';
import EmailRulesManager from './EmailRulesManager';
import LoginPage from './LoginPage';
import Dashboard from './Dashboard'; // Import the new Dashboard component
import Layout from './styles/Layout'; // Import Layout component
import { useIsAuthenticated } from '@azure/msal-react';

function App() {
  const [submissions, setSubmissions] = useState([]);
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to refresh the list of submissions
  const refreshSubmissions = async () => {
    try {
      const response = await fetch('https://sponsorshipbe.azurewebsites.net/api/listSubmissions');
      const data = await response.json();
      setSubmissions(data);
    } catch (error) {
      console.error('Error fetching submissions:', error);
    }
  };

  useEffect(() => {
    console.log('Checking authentication state, isAuthenticated:', isAuthenticated);

    if (isAuthenticated && submissions.length === 0) {
      console.log('Fetching submissions...');
      refreshSubmissions();
    }

    if (isAuthenticated && location.pathname === '/login') {
      navigate('/submissionlist');
    }
  }, [isAuthenticated, submissions.length, location.pathname, navigate]);

  // If user is not authenticated, render only the login page
  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  // Once authenticated, wrap routes in the Layout component
  return (
    <Layout>
      <Routes>
        <Route
          path="/dashboard"
          element={<Dashboard submissions={submissions} />} // Add the dashboard route
        />
        <Route
          path="/submissionlist"
          element={<SubmissionList submissions={submissions} />}
        />
        <Route
          path="/submission/:partitionAndRowKey"
          element={<SubmissionDetails submissions={submissions} refreshList={refreshSubmissions} />}
        />
        <Route
          path="/settings/email-rules"
          element={<EmailRulesManager />}
        />
        {/* Redirect any unknown routes to Submission List */}
        <Route path="*" element={<Navigate to="/submissionlist" />} />
      </Routes>
    </Layout>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import App from './App';
import { msalConfig } from './msalConfig';

// Initialize MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

const RootApp = () => {
  const [isMsalInitialized, setMsalInitialized] = useState(false);

  useEffect(() => {
    const initializeMsal = async () => {
      console.log("[MSAL] Initializing...");
      try {
        // Explicitly initialize the MSAL application to avoid errors.
        await msalInstance.initialize();

        // Handle redirect responses from Azure AD
        const response = await msalInstance.handleRedirectPromise();
        if (response !== null) {
          console.log("[MSAL] Redirect response detected:", response);
          msalInstance.setActiveAccount(response.account);
        } else {
          console.log("[MSAL] No redirect response detected.");
        }

        // Set event callbacks for logging
        msalInstance.addEventCallback((event) => {
          console.log(`[MSAL] Event: ${event.eventType}`);
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            console.log("[MSAL] Login success:", event.payload);
            msalInstance.setActiveAccount(event.payload.account);
          } else if (event.eventType === EventType.LOGIN_FAILURE) {
            console.error("[MSAL] Login failure:", event.error);
          }
        });

        setMsalInitialized(true); // Mark MSAL as initialized
      } catch (error) {
        console.error("[MSAL] Error during initialization:", error);
      }
    };

    initializeMsal();
  }, []);

  // Show loading until MSAL is fully initialized
  if (!isMsalInitialized) {
    return <div>Loading MSAL...</div>;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <App />
      </Router>
    </MsalProvider>
  );
};

// Render the application
const container = document.getElementById('root');
const root = createRoot(container);

root.render(<RootApp />);

export const msalConfig = {
  auth: {
    clientId: "c46fe095-057d-48dd-9245-ebd968a71ea5", // Replace with your Azure AD client ID
    authority: "https://login.microsoftonline.com/c6521ac4-ec25-4e91-a25d-95f324969a51", // Replace with your tenant ID or 'common' for multi-tenant
    redirectUri: window.location.origin, // Dynamically set based on environment
    postLogoutRedirectUri: window.location.origin, // Where to redirect after logout
  },
  cache: {
    cacheLocation: "localStorage", // You can use sessionStorage or localStorage
    storeAuthStateInCookie: true,  // Set to true for IE11/Edge compatibility
  },
  
};

// Request object specifying the permissions/scopes required for login
export const loginRequest = {
  scopes: ['openid', 'profile', 'email'], // Define the required scopes
};
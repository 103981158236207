const LoginPageStyles = {
    root: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F5F5F5',
    },
    container: {
      padding: '2rem',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
    },
    title: {
      textAlign: 'center',
      marginBottom: '1rem',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      marginBottom: '2rem',
      color: '#666666',
    },
    textField: {
      marginBottom: '1rem',
    },
    loginButton: {
      marginTop: '1rem',
      backgroundColor: '#FF6A00',
      '&:hover': {
        backgroundColor: '#FF8C00',
      },
    },
  };
  
  export default LoginPageStyles;
const DashboardStyles = {
    dashboardContainer: {
      width: '100%', // Ensure full width
      height: 'calc(100vh - 64px)', // Adjust height based on screen minus app bar
      padding: '32px', // Padding for spacing
      boxSizing: 'border-box', // Ensure padding is included in the width/height calculation
      overflowY: 'auto', // Add scrolling if content overflows
    },
  };
  
  export default DashboardStyles;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, MenuItem, Typography, Link } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import SubmissionDetailsLayout from './styles/SubmissionDetailsLayout';

const companyOptions = [
  { value: 'Anderson Underground', label: 'Anderson Underground' },
  { value: 'Selcon', label: 'Selcon' },
  { value: 'Sellenriek Construction', label: 'Sellenriek Construction' },
  { value: 'Sellenriek Energy', label: 'Sellenriek Energy' },
  { value: 'UtiliSource', label: 'UtiliSource' },
];

const employeeStatusOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const SubmissionDetails = ({ submissions, refreshList }) => {
  const { partitionAndRowKey } = useParams();
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const isAuthenticated = accounts && accounts.length > 0;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  // Parse partitionKey and rowKey from the URL
  const [partitionKey, rowKey] = partitionAndRowKey.split('-');
  const existingSubmission = submissions?.find(
    (sub) => sub.partitionKey === partitionKey && sub.rowKey === rowKey
  );

  // Set the state for the submission
  const [editedSubmission, setEditedSubmission] = useState(existingSubmission || null);
  const [originalStatus, setOriginalStatus] = useState(existingSubmission?.status || 'Pending'); // Track original status
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(!existingSubmission);

  useEffect(() => {
    if (!existingSubmission) {
      fetch(`https://sponsorshipbe.azurewebsites.net/api/getSubmission/${partitionKey}/${rowKey}`)
        .then((response) => response.json())
        .then((data) => {
          setEditedSubmission(data);
          setOriginalStatus(data.status); // Set original status from fetched data
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [partitionKey, rowKey, existingSubmission]);

  if (loading) {
    return <Typography variant="h6">Loading submission details...</Typography>;
  }

  if (!editedSubmission) {
    return <Typography variant="h6">Submission not found</Typography>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedSubmission((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await fetch(`https://sponsorshipbe.azurewebsites.net/api/updateSubmission`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          partitionKey,
          rowKey,
          ...editedSubmission,
        }),
      });

      if (response.ok) {
        refreshList();

        // If the status has changed, trigger the Logic App
        if (editedSubmission.status !== originalStatus) {
          const logicAppPayload = {
            companyName: editedSubmission.companyName,
            submissionLink: `https://sponsorshipdashboard.lemondesert-2726885f.centralus.azurecontainerapps.io/submission/${partitionKey}-${rowKey}`,
            rowKey,
            partitionKey,
            status: editedSubmission.status,
            sponsorshipName: editedSubmission.sponsorshipName,
            dollarAmount: editedSubmission.dollarAmount,
            contactName: editedSubmission.contactName,
            contactEmail: editedSubmission.contactEmail,
            contactPhone: editedSubmission.contactPhone,
          };

          const logicAppUrl = 'https://prod-25.centralus.logic.azure.com:443/workflows/9d95a683cc5d4b18a2b923413176cf4a/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=nUjS0KPsAsWijIxreroHKxlFqA3k8lRmbiO3GT_VN1k'; // Logic App URL

          await fetch(logicAppUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(logicAppPayload),
          });
        }

        navigate('/submissionlist');
      }
    } catch (error) {
      alert('Error saving submission');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    navigate('/submissionlist');
  };

  // Debugging: Check if fileUrl is present
  console.log("File URL:", editedSubmission.fileUrl);

  return (
    <SubmissionDetailsLayout onSave={handleSave} onCancel={handleCancel} isSaving={isSaving}>
      <TextField
        label="Sponsorship Name"
        name="sponsorshipName"
        value={editedSubmission.sponsorshipName}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Contact Name"
        name="contactName"
        value={editedSubmission.contactName}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Contact Email"
        name="contactEmail"
        value={editedSubmission.contactEmail}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Contact Phone"
        name="contactPhone"
        value={editedSubmission.contactPhone}
        onChange={handleChange}
        fullWidth
      />

      {/* Employee Status */}
      <TextField
        select
        label="Are you an Employee?"
        name="employeeStatus"
        value={editedSubmission.employeeStatus || ''}
        onChange={handleChange}
        fullWidth
      >
        {employeeStatusOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Sponsorship Description"
        name="sponsorshipDescription"
        value={editedSubmission.sponsorshipDescription}
        onChange={handleChange}
        fullWidth
        multiline
        rows={3}
      />

      {/* Display Attachment link if fileUrl exists */}
      {editedSubmission.fileUrl && (
        <Typography variant="body1">
          <Link href={editedSubmission.fileUrl} target="_blank" rel="noopener noreferrer">
            Attachment
          </Link>
        </Typography>
      )}

      <TextField
        label="Dollar Amount"
        name="dollarAmount"
        value={editedSubmission.dollarAmount}
        onChange={handleChange}
        fullWidth
        type="text"
      />

      {/* Payment Mailing Information */}
      <Typography variant="h6">Payment Mailing Information</Typography>

      <TextField
        label="Make Checks Payable To"
        name="payableTo"
        value={editedSubmission.payableTo}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Street"
        name="street"
        value={editedSubmission.street}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="City"
        name="city"
        value={editedSubmission.city}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="State"
        name="state"
        value={editedSubmission.state}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Zip"
        name="zip"
        value={editedSubmission.zip}
        onChange={handleChange}
        fullWidth
      />

      <TextField
        select
        label="Company Name"
        name="companyName"
        value={editedSubmission.companyName}
        onChange={handleChange}
        fullWidth
      >
        {companyOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Status"
        name="status"
        value={editedSubmission.status || 'Pending'}
        onChange={handleChange}
        fullWidth
      >
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Approved">Approved</MenuItem>
        <MenuItem value="Paid">Paid</MenuItem>
        <MenuItem value="Rejected">Rejected</MenuItem>
      </TextField>
    </SubmissionDetailsLayout>
  );
};

export default SubmissionDetails;
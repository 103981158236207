import React from 'react';
import { AppBar, Toolbar, CssBaseline, Box, Container } from '@mui/material';

const RuleLayout = ({ children }) => {
  return (
    <>
      <CssBaseline />
      {/* Remove any default margin, padding, and box shadow */}
      <AppBar 
        position="fixed" 
        sx={{ 
          boxShadow: 'none', 
          backgroundColor: '#FF6A00',  // Dark orange background color
          borderBottom: 'none' // Ensure no border is applied
        }}
      >
        <Toolbar sx={{ minHeight: '64px', padding: '0' }}>
          <Container sx={{ display: 'flex', justifyContent: 'space-between', padding: '0' }}>
            <h1 style={{ margin: 0, color: '#FFF' }}>Email Rules Manager</h1>
          </Container>
        </Toolbar>
      </AppBar>

      {/* Adjust main content area */}
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1, 
          marginTop: '64px', 
          padding: '0', 
          bgcolor: 'background.default',
          minHeight: '100vh',  // Ensure the content takes up the full height of the screen
          overflowX: 'hidden'  // Avoid horizontal scrollbars
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default RuleLayout;
import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, CssBaseline, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard'; // Import dashboard icon
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppStyles from './AppStyles'; // Import your custom styles

const Layout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <>
      <CssBaseline />
      {/* Top App Bar */}
      <AppBar position="fixed" sx={AppStyles.appBar}>
        <Toolbar sx={AppStyles.toolbar}>
          <Box display="flex" alignItems="center">
            <IconButton edge="start" color="inherit" aria-label="menu" sx={AppStyles.menuIcon}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap sx={AppStyles.title}>
              Sponsorship Dashboard
            </Typography>
          </Box>

          {/* Right side: User Profile Avatar */}
          <Box display="flex" alignItems="center">
            <Avatar sx={{ bgcolor: '#FF8C00', cursor: 'pointer' }}>
              <AccountCircleIcon />
            </Avatar>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Left Navigation Drawer */}
      <Drawer variant="permanent" sx={AppStyles.drawer}>
        <Toolbar />
        <Divider />
        <List>
          <ListItem button onClick={() => navigate('/submissionlist')} sx={AppStyles.listItem}>
            <ListItemIcon sx={AppStyles.icon}><HomeIcon /></ListItemIcon>
            <ListItemText primary="Submission List" sx={AppStyles.listItemText} />
          </ListItem>
          <ListItem button onClick={() => navigate('/dashboard')} sx={AppStyles.listItem}> {/* Add Dashboard Link */}
            <ListItemIcon sx={AppStyles.icon}><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" sx={AppStyles.listItemText} />
          </ListItem>
          <ListItem button onClick={() => navigate('/settings/email-rules')} sx={AppStyles.listItem}>
            <ListItemIcon sx={AppStyles.icon}><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Email Rule Manager" sx={AppStyles.listItemText} />
          </ListItem>
        </List>
        <Divider />
      </Drawer>

      {/* Main Content Area */}
      <Box component="main" sx={AppStyles.mainContent}>
        <Toolbar /> {/* Spacer for the AppBar */}
        <Box sx={AppStyles.contentWrapper}>
          {children} {/* Render the child components like SubmissionList or EmailRulesManager */}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
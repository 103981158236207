import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DashboardStyles from './styles/DashboardStyles'; // Custom styles for the Dashboard
import AppStyles from './styles/AppStyles'; // Import your AppStyles for the color palette

const Dashboard = ({ submissions }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [statusAmounts, setStatusAmounts] = useState({});

  useEffect(() => {
    // Group dollarAmount by status
    const statusDollarAmounts = submissions.reduce((acc, submission) => {
      // Remove the $ from dollarAmount and convert it to a number
      const amount = parseFloat(submission.dollarAmount.replace('$', '')) || 0;
      const status = submission.status || 'Pending'; // Handle missing status

      if (!acc[status]) {
        acc[status] = 0;
      }
      acc[status] += amount;
      return acc;
    }, {});

    setStatusAmounts(statusDollarAmounts); // Set state for table data

    // Prepare chart data
    const chartData = Object.keys(statusDollarAmounts).map((status) => ({
      name: status,
      y: statusDollarAmounts[status],
    }));

    // Define a color array for unique colors for each status
    const colorPalette = [
      AppStyles.primaryColor || '#FF6A00',  // Custom colors or defaults
      AppStyles.secondaryColor || '#FF8C00',
      AppStyles.tertiaryColor || '#FFD700',
      AppStyles.quaternaryColor || '#FFA500',
      AppStyles.quinaryColor || '#FF4500',
      '#007ACC',  // Blue
      '#C70039',  // Red
    ];

    // Update chart options
    setChartOptions({
      chart: {
        type: 'pie',
        backgroundColor: AppStyles.chartBackgroundColor || 'transparent',
      },
      title: {
        text: 'Dollar Amount by Status',
      },
      colors: colorPalette, // Apply the custom color palette
      series: [
        {
          name: 'Total Dollar Amount',
          data: chartData,
        },
      ],
    });
  }, [submissions]);

  return (
    <Box sx={DashboardStyles.dashboardContainer}>
      <Typography variant="h4" gutterBottom>
        Dollar Amount by Status
      </Typography>

      {/* Highcharts Pie Chart */}
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />

      {/* Table to show dollar amount by status */}
      <Typography variant="h5" sx={{ marginTop: '20px' }}>
        Dollar Amount Summary
      </Typography>
      <Table sx={{ marginTop: '10px' }}>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="right">Total Dollar Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(statusAmounts).map((status) => (
            <TableRow key={status}>
              <TableCell>{status}</TableCell>
              <TableCell align="right">${statusAmounts[status].toFixed(2)}</TableCell> {/* Format to 2 decimal places */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Dashboard;
import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import AppStyles from './SubmissionDetailsAppStyles'; // Correct path

const SubmissionDetailsLayout = ({ children, onSave, onCancel, isSaving }) => {
  return (
    <Container sx={AppStyles.container}>
      <Typography variant="h4" sx={AppStyles.title}>
        Edit Submission
      </Typography>
      <Box sx={AppStyles.content}>
        {children} {/* Form content passed from the submission details screen */}
      </Box>
      <Box sx={AppStyles.buttons}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save and Close'}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          disabled={isSaving}
        >
          Cancel
        </Button>
      </Box>
    </Container>
  );
};

export default SubmissionDetailsLayout;
const RuleAppStyles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
  },
  title: {
    marginBottom: '24px',
    fontWeight: 'bold',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    backgroundColor: 'white',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: 24,
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
};

export default RuleAppStyles;